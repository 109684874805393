.centerh {
  /*border: 1px solid red;*/
  /*width:100%*/
  display: flex;
  justify-content: center;
}

.aboutcards {
  font-size: 20px;
}
