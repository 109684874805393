.tweet {
  font-family: "Courier New", Courier, monospace;
  background-color: #f5f8fa;
  border: 1px solid #e1e8ed;
  border-radius: 10px;
  padding: 20px;
  max-width: 600px;
  margin: 20px;
}

.username {
  color: #1da1f2;
  font-weight: bold;
}

.content {
  color: #14171a;
  margin-top: 10px;
}

.time {
  color: #657786;
  font-size: 0.8em;
  margin-top: 15px;
}
